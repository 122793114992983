import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { RichText } from "../../../textFields";
import { shouldForwardProp } from "../../../utils";

type PpContainer = {
  $transparent: boolean;
};

export const Container = styled("div", { shouldForwardProp })<PpContainer>`
  ${({ $transparent, theme }): SerializedStyles => css`
    text-align: center;
    border-radius: ${theme.shape.siteBorderRadius.lg};
    padding: ${theme.spacing(3, 2)};
    ${!$transparent &&
    css`
      background-color: ${theme.palette.background.paper};
      box-shadow: ${theme.shadows[1]};
    `};
  `};
`;

export const ShadowHeader = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    position: relative;
    line-height: 0;
    ${theme.cssMixins.processCardShadowHeader};
    display: block;
    color: ${theme.palette.site.shadowHeader.dark};
  `}
`;

export const Description = styled(RichText)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.lineClamp({ lines: 3 })};
    position: relative;
    z-index: 100;
  `}
`;

export const Title = styled(Typography)`
  position: relative;
  z-index: 100;
`;
