import React from "react";
import { Grid } from "@mui/material";
import { Container, Description, ShadowHeader, Title } from "./styles";

export type PpCard = {
  description: string;
  shadowHeader: string;
  title: string;
  transparent: boolean;
};

export function Card({
  description,
  shadowHeader,
  title,
  transparent,
}: PpCard): React.ReactElement {
  return (
    <Container $transparent={transparent}>
      <Grid
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
      >
        <Grid item>
          <ShadowHeader variant="processCard--number">
            {shadowHeader}
          </ShadowHeader>
          <Title gutterBottom color="primary" variant="h3">
            {title}
          </Title>
        </Grid>
        <Grid item>
          <Description
            gutterBottom
            color="primary"
            text={description}
            variant="card--desc"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
