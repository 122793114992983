/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import { Grid, GridDirection } from "@mui/material";
import { TpLinkButton } from "../../buttons";
import { SectionHeading } from "../../containers";
import { Link } from "../../links";
import { Media, MediaPlacementType, PpMedia } from "../../media";
import { getPathname } from "../../utils";
import { Card, PpCard } from "./Card";
import { CardItem, CTAButton, StyledContainer } from "./styles";

type PpProcess = {
  ctaLink: TpLinkButton;
  description: string;
  media?: PpMedia["media"];
  mediaPlacement?: string;
  sectionName?: string;
  shiftedCards?: boolean;
  steps: Array<PpCard>;
  title: string;
  transparentCards?: boolean;
};

export function Process({
  ctaLink,
  description,
  media,
  mediaPlacement,
  sectionName,
  shiftedCards = true,
  steps,
  title,
  transparentCards = false,
}: PpProcess): React.ReactElement {
  let direction = "row" as GridDirection;

  switch (mediaPlacement) {
    case MediaPlacementType.LEFT:
      direction = "row-reverse";
      break;
    default:
      break;
  }

  return (
    <StyledContainer>
      <Grid container alignItems="center" direction="column" spacing={4}>
        <Grid
          container
          item
          alignItems="center"
          direction={direction}
          justifyContent={media ? "space-between" : "center"}
        >
          <Grid item lg={media ? 4 : 6} sm={media ? 5 : 9}>
            <SectionHeading
              alignItems={{ xs: "center", sm: media ? "flex-start" : "center" }}
              description={description}
              sectionName={sectionName}
              standardWidth={false}
              textAlign={media ? "left" : "center"}
              title={title}
            />
          </Grid>
          {media && (
            <Grid
              item
              zeroMinWidth
              sm={6}
              style={{ maxWidth: "100%" }}
              sx={{ display: { sm: "block", xs: "none" } }}
            >
              <Media media={media} />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          spacing={4}
        >
          {steps.map((step, index) => (
            <CardItem
              key={step.shadowHeader}
              item
              xs
              $num={index}
              $shiftedCards={shiftedCards}
            >
              <Card
                description={step.description}
                shadowHeader={step.shadowHeader}
                title={step.title}
                transparent={transparentCards}
              />
              {index + 1 === steps.length && ctaLink && shiftedCards && (
                <Link to={getPathname(ctaLink.page.url)}>
                  <CTAButton color="secondary" variant="contained">
                    {ctaLink.title}
                  </CTAButton>
                </Link>
              )}
            </CardItem>
          ))}
        </Grid>
        {!shiftedCards && (
          <Grid item>
            <Link to={getPathname(ctaLink.page.url)}>
              <CTAButton color="secondary" variant="contained">
                {ctaLink.title}
              </CTAButton>
            </Link>
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  );
}
