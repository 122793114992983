import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Button } from "../../buttons";
import { SectionContainer } from "../../containers";
import { shouldForwardProp } from "../../utils";

export const StyledContainer = styled(SectionContainer)`
  ${({ theme }): SerializedStyles => css`
    text-align: center;
    ${theme.breakpoints.up("sm")} {
      text-align: left;
    }
  `}
`;

type PpNum = {
  $num: number;
  $shiftedCards: boolean;
};

export const CardItem = styled(Grid, { shouldForwardProp })<PpNum>`
  ${({ $num, $shiftedCards, theme }): SerializedStyles => css`
    ${theme.breakpoints.down("sm")} {
      &:not(:last-child)::after {
        content: "";
        display: inline-block;
        margin-top: ${theme.spacing(4)};
        height: 45px;
        width: 1px;
        background-image: linear-gradient(
          ${theme.palette.primary.main} 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-size: 1px 10px;
        background-repeat: repeat-y;
      }
    }
    ${theme.breakpoints.up("sm")} {
      display: flex;
      flex-direction: column;
      align-items: center;
      ${$shiftedCards &&
      css`
        // transform: translateY(calc(140px * ${$num}));
        margin-top: calc(150px * ${$num});
      `};
    }
  `}
`;

export const CTAButton = styled(Button)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(3)};
  `}
`;

export const LoadingSpinner = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    height: 800px;
    ${theme.breakpoints.up("sm")} {
      height: 570px;
    }
  `}
`;
